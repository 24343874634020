@import url("https://fonts.googleapis.com/css?family=Instrument+Sans:400,600|Instrument+Serif:300,400,400italic|Inter:500,300");

body, #root {
    height: 100%;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    top: 10px;
    width: 20px;
    height: 20px;
    border: 3px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

form.auth-form .auth-submit-btn.disabled {
    cursor: default !important;
    background: gray !important;

    &:hover {
        background-color: gray !important;
    }
}

@supports (-webkit-overflow-scrolling: touch) {
    input:focus, textarea:focus {
        font-size: 16px;
    }
}

.lds-ring.dark div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    top: 10px;
    width: 18px !important;
    height: 18px !important;
    border: 3px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--accent, #2467eb) transparent transparent transparent !important;
}

.notification-close {
    cursor: pointer;
    border-radius: 3px;
    padding: 1px;
    position: absolute;
    top: 6px;
    bottom: auto;
    left: auto;
    right: 8px;
}

.auth-submit-btn {
    background-image: linear-gradient(135deg, var(--accent, #2467eb), var(--accenthover, #2467eba3) 52%);
}

.auth-submit-btn:hover {
    background-color: var(--accent, #2467eb) !important;
}

.auth-link-redirect {
    color: var(--accent, #2467eb);
    opacity: 1;
}

.auth-link-redirect:hover {
    color: var(--accenthover, #2467eb);
    opacity: 1;
}

.lowercased-layout {
    text-transform: lowercase !important;
}


/*legatum custom css classes*/
.auth-page-container {
    background-image: url('https://i.postimg.cc/d3Mz7Fvw/LOGIN-LWC.jpg');
    background-size: cover;
}

.auth-page-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1); /* Adjust this for the desired opacity */
    z-index: 1;
    pointer-events: none; /* Allow clicks to pass through the overlay */

}

.auth-page-content {
    position: relative;
    z-index: 2; /* Make sure the content is above the overlay */
}

.auth-page-title {
    color: white;
}

.text-block-29 {
    color: white;
}

.auth-submit-btn-label {
    color: #145ec7;
    font-weight: bold;
}

.text-block-31, .auth-link-redirect-2 {
    color: white;
}

.auth-input {
    background-color: #fff2f2;
}


.auth-page-title {
    font-family: "Instrument Serif", Helvetica;
    font-size: 38px;
    font-style: italic;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 43px;
    margin-bottom: -5px;
    margin-left: 1px;
    margin-top: -1px;
    min-height: 49px;
    text-align: center;
    white-space: nowrap;
    width: 292px;
}

.text-block-29, .auth-link-redirect, .auth-title-desc, .text-block-31 {
    color: white;
    font-family: "Inter", Helvetica;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    opacity: 1;
}

.auth-submit-btn-label{
    font-family: "Inter", Helvetica;

}

.text-helper{
    color : white;
    font-family: "Inter", Helvetica;
}


input {
    font-family: "Inter", Helvetica;
    font-weight: 300;
    color: #145ec7;
    letter-spacing: -0.44px;
    line-height: 23px;
    width: 100%;
    font-size: 17.5px;
}

.auth-logo-img{
    width: auto;
}

.data-collection-form {
    display: flex;
    align-items: baseline;
    flex-direction: row;
    gap: 10px;
    flex-flow: row;
}

#dataCollectionOptIn{
width: auto;
}

.auth-page-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-decoration: none;
    overflow: auto;
}

.auth-page-block {
    justify-content: space-between;
    align-items: center;
    width: 320px;
    overflow: visible;
}

.text-block-31 > a {
    color: white;
}

.data-collection-form > div > label {
    font-size: 14px;
}

.auth-footer-links {
    color: white;
    font-size: 14px;
    font-family: "Inter", Helvetica;
}
.auth-footer-links{
    opacity:1!important;
}
.div-block-35 {
    border: .5px solid #fff!important;
}
